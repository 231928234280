<template>
  <div class="recharge-page">
    <headerBar :title="i18n.t('recharge.title')" class="red" :showArrow="true" @back="back"></headerBar>

    <div class="recharge-top">
      <div class="label">{{i18n.t('recharge.label1')}}($)</div>
      <div class="money">$ {{userMoney}}</div>
      <div class="flex tabs">
        <div class="flex-1">
          <div>{{i18n.t('recharge.label2')}}($)</div>
          <div class="count">{{totalAmountWait}}</div>
        </div>
        <div class="flex-1">
          <div>{{i18n.t('recharge.label3')}}($)</div>
          <div class="count">{{totalAmount}}</div>
        </div>
      </div>
    </div>

    <div class="recharge-form">
      <div class="label">{{i18n.t('recharge.label4')}}</div>
      <div class="input">
        <input type="number" v-model="form.number" :placeholder="i18n.t('recharge.placeholder')">
<!--        <label v-if="exchange_rate > 0">汇率转换：$1 ≈ {{ exchange_rate }} MXN</label> <br />-->
<!--        <label v-if="exchange_rate > 0 && form.number > 0">兑换金额：  {{ exchange_result }} MXN ≈ ${{ form.number }}</label>-->
      </div>
    </div>
<br />
    <div class="fast-list flex-wrap">
      <div class="list" v-for="(item, i) in fastList" :key="i" @click="setMoney(item)">{{item}}</div>
    </div>
    <div class="recharge-form" v-if="show_collection === 1">
      <div class="label">{{i18n.t('recharge.label5')}}</div>
      <div>
        <van-radio-group v-model="form.type" direction="horizontal" style="height: 100px;line-height: 40px">
          <van-radio :name="item.protocol" v-for="(item,i) in RechargeInfo" :key="i">
            <span v-if="item.protocol === 'USDT-TRC20'">
              TRC20
            </span>
            <span v-if="item.protocol === 'USDT-ERC20'">
              ERC20
            </span>
            <template #icon="props">
              <img class="img-icon" style="width: 1rem;height: 30px" :src="props.checked ? usdt_select : usdt_no" />
            </template>
          </van-radio>
          <van-radio :name="item.Country" v-if="BankInfo !== undefined" v-for="(item,i) in BankList" :key="item.Country">
            <template #icon="props">
              <img class="img-icon" style="width: 1rem;height: 30px" :src="props.checked ? bank_select : bank_no" />
            </template>
            <template v-if="item.Country == 'Bank'">
              {{i18n.t('bind.BankTransfer')}}
            </template>
            <template v-else-if="item.Country == 'quick'">
              {{i18n.t('recharge.quick')}}
            </template>
            <template v-else>
              {{item.Country}}
            </template>
          </van-radio>
        </van-radio-group>
<!--        <van-button type="danger">主要按钮</van-button>-->
      </div>
    </div>
  <br />
  <br />
    <div class="btn">
      <Button @click="submit">{{i18n.t('recharge.btn')}}</Button>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
import { rechargeStatistics, doRecharge, userInfo,ListPayManage,getCompanyBank } from '@/api/user'
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      form: {
        number: '',
        type: 1,
      },
      activeIcon: 'https://img01.yzcdn.cn/vant/user-active.png',
      inactiveIcon: 'https://img01.yzcdn.cn/vant/user-inactive.png',
      usdt_no: require('@/assets/imgs/usdt_no.png'),
      usdt_select: require('@/assets/imgs/usdt_select.png'),
      bank_no: require('@/assets/imgs/bank_no.svg'),
      bank_select: require('@/assets/imgs/bank_select.svg'),
      userMoney: 0,
      fastList: [50, 100, 200, 300, 500, 600, 800, 1000, 2000, 5000, 8000, 10000],
      address: '',
      totalAmount: 0,
      RechargeInfo: {},
      BankInfo: undefined,
      BankList: undefined,
      totalAmountWait: 0,
      exchange_result: 0,
    }
  },
  computed: {
    show_collection() {
      return this.$store.state.show_collection
    },
    exchange_rate() {
      return this.$store.state.exchange_rate
    },
  },
  watch: {
    'form.number': function (val) {
      if (this.exchange_rate > 0) {
        this.exchange_result = (val * this.exchange_rate).toFixed(2)
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      rechargeStatistics().then(res => {
        this.totalAmount = res.data.TotalAmount
        this.totalAmountWait = res.data.TotalAmountWait
      })

      userInfo().then(res => {
        this.userMoney = res.data.UserAccount.Balance
      })
      ListPayManage().then(res => {
        this.RechargeInfo = res.data.list
        // 存入缓存
        window.localStorage.setItem('RechargeInfo', JSON.stringify(res.data.list))
      })
      getCompanyBank().then(res => {
        console.log(res.data)
        this.BankList = res.data.Items
        if(res.data.Items.length > 0 ) {
          this.BankInfo = res.data.Items[0]
        }
        // 存入缓存
        window.localStorage.setItem('BankInfo', JSON.stringify(this.BankList))
      })
    },
    back() {
      this.$router.go(-1)
    },
    setMoney(money) {
      this.form.number =  money
    },
    goToRecharge(){
      doRecharge({
        amount: this.form.number + '',
      }).then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('recharge.success'))
          this.$router.push({
            name: 'kefu'
          })
        } else {
          this.$toast(res.msg)
        }
      })
    },
    submit() {

      if (!this.form.number) {
        this.$toast({
          message: this.i18n.t('recharge.placeholder'),
          icon: 'warning-o'
        })
        return
      }

      if(this.show_collection === 1) {
        window.localStorage.setItem("amount",this.form.number)
        window.localStorage.setItem("type",this.form.type)
        if(this.form.type.toString().toLocaleLowerCase() === "quick") {
          // 跳转到客服页面
          this.$router.push({
            name: 'bindCredit',
          })
          return;
        }
        // Wise
        if(this.form.type.toString().toLocaleLowerCase() === "bank" || this.form.type.toString().toLocaleLowerCase() === "wise") {
          // 跳转到客服页面
          this.$router.push({
            name: 'kefu',
          })
          return;
        }
        this.$router.push({
          name: 'rechargeDetail',
          query: {
            amount: this.form.number,
            type: this.form.type,
          }
        })
      } else {
        this.goToRecharge()
      }

    }
  }
}
</script>